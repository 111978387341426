* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family:
    'Inter',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

/**
 * Custom file splash screen
 */
.fuse-splash-screen {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #1a0424;
  color: #f9fafb;

  z-index: 9999;
  pointer-events: none;
  opacity: 1;

  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

#spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
  width: 56px;
}

#spinner > div {
  width: 12px;
  height: 12px;
  background-color: #b641eb;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
  animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

#spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

#spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes fuse-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.bg-purple-500 {
  background-color: rgb(153, 39, 176, 1);
}

.bg-red-600 {
  background-color: rgb(229, 57, 53, 1);
}

.bg-yellow-400 {
  background-color: rgb(255, 238, 88, 1);
}

.bg-gray-600 {
  background-color: rgb(117, 117, 117, 1);
}

.bg-gray-400 {
  background-color: rgb(189, 189, 189, 1);
}

.slider-container {
  /* height: 100vh; */
  height: 340px;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 0;
  min-height: 0;
  padding: 10px;
}

.slick-slider {
  touch-action: pan-y;
  user-select: none;
  margin: 30px auto 50px;

  display: block;
  position: relative;

  box-sizing: border-box;
}

.slick-list {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;

  margin: 0;
  padding: 0 !important;
  overflow: hidden;
  position: relative;

  width: 35rem;
  border: 1px solid #000;
}

/* 
.slick-slide {
  width: 559px !important;
  height: 340px !important;
} 
*/

.slick-prev,
.slick-next {
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  height: 30px;
  line-height: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translate(0, -50%);
  width: 30px;

  display: block;
  background: #a621f4;
  outline: 0;
  z-index: 1;
}

.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-dots {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.vjs-matrix .video-js {
  color: #9807fc !important;

  &:hover {
    .vjs-big-play-button {
      border-color: #6529ae !important;
      background-color: #6529ae !important;
    }
  }
}

.video-js .vjs-big-play-button {
  font-size: 3em !important;
  border-radius: 100% !important;
  width: 1.5em !important;
  height: 1.5em !important;
  margin-left: -0.8em !important;
  border: none !important;
  background-color: rgba(27, 27, 28, 0.7) !important;
}

.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
  background: #9807fc !important;
}

.video-js .vjs-control-bar {
  background-color: transparent !important;
  bottom: 10px !important;
}

.video-js .vjs-progress-control {
  @media (max-width: 768px) {
    margin-top: 0 !important;
  }
}

.video-js .vjs-time-control {
  margin-top: 1.2em !important;
}

.video-js .vjs-remaining-time span,
span.vjs-icon-placeholder::before {
  color: #fff !important;
}

.slick-arrow {
  position: absolute;
  outline: none;
  top: calc(50% - 10px);
  padding: 0;
  width: 45px;
  height: 35px;
  background-color: #9807fc;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  i {
    color: #fff;
    font-size: 24px;
  }
  &.slick-arrow {
    transition: 0.3s;
    opacity: 0;
  }
  &.arafat-prev {
    left: -10px;
  }
  &.arafat-next {
    right: -10px;
    transform: rotate(180deg);
  }
}

.slick-track {
  display: flex !important;
  gap: 10px !important;

  .zoom-image {
    max-width: 100%;
  }
}

.slick-list {
  border: none !important;
}

.slick-list > div {
  margin-left: 0;
}

.navbar-toggle-button-hidden {
  display: none;
}

.navbar-toggle-button-visible {
  display: block;
}
